import React, { useState } from 'react';
import { Button } from '@mui/material';
import Screenshot from '../Screenshot';

function InternetCheckout({}) {
  const [activeSlide, setActiveSlide] = useState(0);

  return (
    <div className="slides">
      {/* <ul className="nav">
        <li className={'nav-block-1' + (activeSlide === 0 ? ' active' : '')}>
          <Button onClick={() => setActiveSlide(0)}></Button>
        </li>
        <li className={'nav-block-2' + (activeSlide === 1 ? ' active' : '')}>
          <Button onClick={() => setActiveSlide(1)}></Button>
        </li>
        <li className={'nav-block-3' + (activeSlide === 2 ? ' active' : '')}>
          <Button onClick={() => setActiveSlide(2)}></Button>
        </li>
      </ul> */}

      <div
        className={
          'slide slide-1' +
          (activeSlide === 0 ? ' active' : '') +
          (activeSlide > 0 ? ' left' : '')
        }>
        <h2>
          <span className="number">Step 1</span> Choose an Internet Plan
        </h2>
        <Screenshot
          imageFile={'/images/mi-landing/screenshot-plan-builder-start.png'}
          mobile
        />
      </div>

      <div
        className={
          'slide slide-2' +
          (activeSlide === 1 ? ' active' : '') +
          (activeSlide === 2 ? ' left' : '')
        }>
        <h2>
          <span className="number">Step 2</span> Choose a TV Plan
        </h2>
        <Screenshot
          imageFile={'/images/mi-landing/screenshot-plan-builder-tv.png'}
          mobile
        />
      </div>

      <div className={'slide slide-3' + (activeSlide === 2 ? ' active' : '')}>
        <h2>
          <span className="number">Step 3</span> Place the Order
        </h2>
        <Screenshot
          imageFile={'/images/mi-landing/screenshot-plan-builder-summary.png'}
          mobile
        />
      </div>
    </div>
  );
}

export default InternetCheckout;
