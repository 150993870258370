import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withPrefix } from 'gatsby-link';

import MenuIcon from '@mui/icons-material/Menu';

import './index.css';

function Screenshot({
  siteConfig,
  imageFile,
  mobile,
  className,
  autoScroll,
  onScrollStart,
  onScrollStop
}) {
  const ref = useRef();
  const autoScrollTimeout = useRef();

  useEffect(() => {
    if (autoScroll) {
      autoScrollTimeout.current = setTimeout(() => {
        ref.current.scroll({ top: autoScroll, behavior: 'smooth' });
      }, 1000);
    }

    return () => {
      if (autoScrollTimeout.current) {
        clearTimeout(autoScrollTimeout.current);
      }
    };
  }, []);

  function scrollStart() {
    if (onScrollStart) {
      onScrollStart();
    }
  }

  function scrollStop() {
    if (onScrollStop) {
      onScrollStop();
    }

    ref.current.scroll({
      top: autoScroll ? autoScroll : 0,
      behavior: 'smooth'
    });
  }

  return (
    <div
      className={
        'screenshot' +
        (mobile ? ' mobile' : '') +
        (className ? ' ' + className : '')
      }>
      <img
        alt="PC frame"
        src={withPrefix(
          '/images/mi-landing/' +
            (mobile ? 'mobile-screenshot' : 'pc-screenshot') +
            '.svg'
        )}
      />

      <div className="frame">
        <div className="header">
          <MenuIcon />
          <img alt={siteConfig.brand} src={withPrefix(siteConfig.logo)} />
        </div>

        <div
          className="content"
          ref={ref}
          onScroll={() => {
            scrollStart();
          }}
          onMouseOver={(event) => event.stopPropagation()}
          onMouseOut={(event) => {
            scrollStop();
          }}>
          <img
            alt="Screenshot of MovingIN Dashboard on Desktop"
            src={withPrefix(imageFile)}
          />
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    siteConfig: state.commonData.siteConfig
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {}
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Screenshot);
