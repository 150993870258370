import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import '../components/TXLandingPage/index.css';
import '../components/Default/index.css';

import LandingMI from '../components/LandingMIRebuild';
import LandingMIMobile from '../components/LandingMIMobile';
import LandingSmarterChoice from '../components/LandingSC';

import * as webSocketActions from '../redux/actions/webSocketActions';

function DefaultPage({ actions, serviceAddress, siteConfig, webSocket }) {
  return siteConfig.id === 'sc' ? (
    <LandingSmarterChoice />
  ) : (
    <>
      <LandingMI />
      <LandingMIMobile />
    </>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    siteConfig: state.commonData.siteConfig
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DefaultPage);
