import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withPrefix } from 'gatsby-link';

import AddressSearch from '../../AddressSearch';
import ContactPage from '../../ContactPage';

import './index.css';

function ContentDashboard({ siteConfig }) {
  const [frame, setFrame] = useState(null);

  const [pcIsScrolling, setPCisScrolling] = useState(null);
  const pcScrollTimeout = useRef();

  return (
    <>
      <div className="mi-landing-content-bottom" id="miLandingBottom">
        <ContactPage title={'Want to know more?'}>
          {/* <div className="like-what-you-saw">
            <h2>Try it out</h2>
            <p>
              Use our property set up tool to fully customize this dashboard to
              your exact specifications in less than 5 minutes.
            </p>
            <p>To get started, enter your property address below.</p>

            <div className="address-search-container">
              <AddressSearch
                hideSearchBy
                mode="address"
                addressLabel={'Enter an apartment address to see how it works'}
                searchButtonLabel={'Start Property Setup'}
                handleAddressSelection={(address) => {
                  dataApi
                    .createDemoServiceProfile(
                      address.street_line,
                      null,
                      address.city,
                      address.state,
                      address.zipcode
                    )
                    .then((result) => {
                      console.log('createDemoServiceProfile result', result);
                      navigate(
                        '/property-setup?property=' + btoa(result.ProfileKey)
                      );
                    });
                }}
                suppressSecondarySelect
              />
            </div>
          </div> */}
        </ContactPage>

        <div className="software">
          <h3 className="hand">
            Seamlessly integrates with your property management software
          </h3>
          <img src={withPrefix('/images/software/yardi.svg')} />
          <img src={withPrefix('/images/software/realpage.svg')} />
          <img src={withPrefix('/images/software/entrata.svg')} />
          <img src={withPrefix('/images/software/mri.svg')} />
          <img src={withPrefix('/images/software/resman.svg')} />
          <img src={withPrefix('/images/software/appfolio.svg')} />
        </div>
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    siteConfig: state.commonData.siteConfig
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {}
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentDashboard);
