import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withPrefix } from 'gatsby-link';
import { navigate } from 'gatsby-link';
import { Button } from '@mui/material';

import CheckIcon from '@mui/icons-material/Check';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { useOnScreen } from '../../api/dataApi';

import * as webSocketActions from '../../redux/actions/webSocketActions';
import * as serviceAddressActions from '../../redux/actions/serviceAddressActions';

import './index.css';

import Screenshot from './Screenshot';
import RequiredTasks from './RequiredTasks';
import InternetCheckout from './InternetCheckout';
import ContentBottom from '../LandingMI/ContentBottom';
import ExploreMore from '../LandingMI/ExploreMore';
import LoginButton from '../LandingMI/LoginButton';
import CanvasFireworks from '../CanvasFireworks';

function LandingMIMobile({ actions, serviceAddress, siteConfig }) {
  const ref = useRef();
  const lastSlideRef = useRef();
  const lastSlideIsVisible = useOnScreen(lastSlideRef);
  const [showConfetti, setShowConfetti] = useState(false);

  useEffect(() => {
    if (lastSlideIsVisible) {
      setTimeout(() => setShowConfetti(true), 1000);
    } else {
      setShowConfetti(false);
    }
  }, [lastSlideIsVisible]);

  return (
    <div className="mi-landing-mobile" ref={ref}>
      <div className="section section-1">
        <LoginButton />

        <h1>
          Simplify the{' '}
          <img alt={siteConfig.brand} src={withPrefix(siteConfig.logo)} />{' '}
          process
        </h1>

        <h2>
          Make the moving in process easier for new leases and save your onsite
          staff countless hours with {siteConfig.brand} Multifamily.
        </h2>
      </div>

      <div className="section section-2">
        <h1>
          The concept behind{' '}
          <img alt={siteConfig.brand} src={withPrefix(siteConfig.logo)} /> is
          simple:
        </h1>
        <h2>
          Help your new leases with all things moving related... <br />
          so that your onsite team doesn't have to.
        </h2>

        <p>
          But to accomplish that, first we had to develop some pretty amazing
          tech.
        </p>
        <p>
          The {siteConfig.brand} dashboard below is the result of all that
          creative effort. It quickly answers anything from "what utilities do I
          need to set up in my name?" to "what schools will my kids go to?"
          ...and everything in between.
        </p>

        {/* <p>
          And best of all, {siteConfig.brand} is{' '}
          <strong>completely free</strong> to use for both you and your
          residents.
        </p> */}
        <p>Improve the move with {siteConfig.brand}.</p>
      </div>

      <div className="content section section-3">
        <h2>It all starts with a friendly invitation.</h2>
        <img
          alt="Email invitation"
          className="mobile-welcome-06"
          src={withPrefix('/images/mi-landing/mobile-welcome-06.png?v=2')}
        />

        <h1>
          <div className="content">You Approve - We Invite</div>
        </h1>

        <p>
          Shortly after you approve a new lease in your onsite property
          management system, {siteConfig.brand} will send your soon-to-be new
          resident a co-branded email welcoming them to their new home and
          introducing them to their own personalized {siteConfig.brand}{' '}
          dashboard.
        </p>
        <p>
          {' '}
          <strong>You don’t do anything extra at all.</strong>
          <br />
          <br />
          New lease data is automatically transferred to {siteConfig.brand} by
          your onsite system.
        </p>
        <p>
          Just continue approving new leases like you always do, and{' '}
          {siteConfig.brand} will take care of the rest.
        </p>
      </div>

      <div className="content section section-4">
        <h1>
          <em className="title">
            <CheckIcon /> Easy to Use
          </em>
          <div className="content">
            This beautifully designed, pre-arrival amenity works on any device
            and walks your new leases through the entire move-in process.
          </div>
        </h1>

        <Screenshot imageFile={'/images/mi-landing/screenshot-dashboard.png'} />
        <Screenshot
          imageFile={'/images/mi-landing/screenshot-dashboard-mobile.png'}
          mobile
        />

        <h2>Reduce the stress of moving.</h2>

        <p>
          Our user friendly MovingIN dashboard simplifies the moving in process
          for new leases by breaking all their moving related tasks into small,
          bite-sized actions that can be easily tackled one item at a time.
        </p>

        <p>
          This helps them efficiently manage their entire move, from start to
          finish, in a convenient and organized manner that gets them ready to
          enjoy their amazing new home, stress-free, on day one.
        </p>
      </div>

      <div className="content section section-5">
        <h1>
          <em className="title">
            <CheckIcon /> Required Tasks
          </em>{' '}
          <div className="content">
            We remind new leases of everything they need to do, so you don't
            have to.
          </div>
        </h1>

        <RequiredTasks />
      </div>

      <div className="content section section-6">
        <h1>
          <em className="title">
            <CheckIcon /> Convenient
          </em>{' '}
          <div className="content">
            Your new leases will love how easy MovingIN is to use, especially
            when it comes to viewing, choosing and ordering things like Internet
            and TV.
          </div>
        </h1>

        <InternetCheckout />
      </div>

      <div className="content section section-7">
        <h1>
          <em className="title">
            <CheckIcon /> Informative
          </em>{' '}
          <div className="content">
            We've done all the other research for them too.
          </div>
        </h1>

        <p>
          We also assist new leases with all their other moving related tasks –
          like reminding them to update all their addresses.
        </p>

        <Screenshot
          imageFile={'/images/mi-landing/screenshot-change-address-mobile.png'}
        />

        <p>
          Not just reminding them, but showing them the when, where and how of
          doing it all.
        </p>

        <ul className="checklist">
          <li>
            <CheckIcon />
            File Change of Address with USPS
          </li>
          <li>
            <CheckIcon />
            Update Driver’s License
          </li>
          <li>
            <CheckIcon />
            Update Vehicle Registration
          </li>
          <li>
            <CheckIcon />
            Update Voter Registration
          </li>
        </ul>
      </div>

      <div className="content section section-8">
        <h1>
          <em className="title">
            <CheckIcon /> Helpful
          </em>{' '}
          <div className="content">
            We've even identified which schools their kids will likely go to.
          </div>
        </h1>

        <Screenshot
          imageFile={'/images/mi-landing/screenshot-dashboard.png'}
          autoScroll={1000}
          className="schools-screenshot"
        />

        <p>
          Public schools assigned to your community are displayed along with
          location and enrollment links.
        </p>
        <p>
          If the property has other community services or partners that they'd
          like us to display, we can do that as well. It's a great way to remind
          new leases of other great amenities you offer.
        </p>
      </div>

      <div className="content section section-9">
        <h1>
          <div className="content">We thought of everything.</div>
        </h1>

        <p>
          Moving boxes, hanging a TV, groceries delivered, even booking a pet
          sitter on moving day - they can find it all on their{' '}
          {siteConfig.brand} dashboard.
          <br />
          <br />
          <strong>The easiest move they'll ever make!</strong>
        </p>

        <div className="explore-more-container">
          <ExploreMore />
        </div>
      </div>

      <div className="content section section-10" ldslideRef>
        <h1>
          <div className="content">
            {' '}
            You can't make moving fun,
            <br />
            but you can make it easy with
          </div>
          <img alt={siteConfig.brand} src={withPrefix(siteConfig.logo)} />
        </h1>

        <h1
          ref={lastSlideRef}
          className={'bottom' + (lastSlideIsVisible ? ' visible' : '')}>
          <div className="content">Ok, and maybe a little fun...</div>
        </h1>

        {showConfetti ? <CanvasFireworks /> : null}
      </div>

      <ContentBottom />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    siteConfig: state.commonData.siteConfig
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch),
      serviceAddressUpdated: bindActionCreators(
        serviceAddressActions.serviceAddressUpdated,
        dispatch
      )
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LandingMIMobile);
