import React, { useState } from 'react';
import { Button } from '@mui/material';
import Screenshot from '../Screenshot';

function RequiredTasks({}) {
  const [activeSlide, setActiveSlide] = useState(0);

  return (
    <>
      <div className="text">
        <Screenshot
          imageFile={'/images/mi-landing/screenshot-utilities.png'}
          className={activeSlide === 0 ? ' active' : ''}
        />
        {/* <ul className="nav">
          <li className={'nav-block-1' + (activeSlide === 0 ? ' active' : '')}>
            <Button onClick={() => setActiveSlide(0)}></Button>
          </li>
          <li className={'nav-block-2' + (activeSlide === 1 ? ' active' : '')}>
            <Button onClick={() => setActiveSlide(1)}></Button>
          </li>
          <li className={'nav-block-3' + (activeSlide === 2 ? ' active' : '')}>
            <Button onClick={() => setActiveSlide(2)}></Button>
          </li>
        </ul> */}

        <div
          className={
            'block block-1' +
            (activeSlide === 0 ? ' active' : '') +
            (activeSlide > 0 ? ' left' : '')
          }>
          <h2>
            <strong>Utilities</strong> <br />
            Do they, or don’t they?
          </h2>
          <p>
            Questions like which utilities they need to set up in their name and
            which they don’t are quickly and accurately answered by the
            dashboard, reducing calls to the leasing office and letting your
            onsite staff focus on higher priority tasks.
          </p>
        </div>

        <Screenshot
          imageFile={'/images/mi-landing/screenshot-renters.png'}
          className={activeSlide === 1 ? ' active' : ''}
        />
        <div
          className={
            'block block-2' +
            (activeSlide === 1 ? ' active' : '') +
            (activeSlide === 2 ? ' left' : '')
          }>
          <h2>
            <strong>Renters Insurance</strong> <br />
            Promote your Preferred Provider
          </h2>
          <p>If you require Renters Insurance, we've got that covered too.</p>
          <p>
            We can even promote and highlight your{' '}
            <strong>Preferred Provider</strong>
          </p>
        </div>

        <Screenshot
          imageFile={'/images/mi-landing/screenshot-electric.png'}
          className={activeSlide === 2 ? ' active' : ''}
        />
        <div className={'block block-3' + (activeSlide === 2 ? ' active' : '')}>
          <h2>
            <strong>Texas</strong> <br />
            Power to Choose
          </h2>
          <p>
            For properties in deregulated areas of Texas we offer new leases the
            added convenience of having a retail electric shopping option built
            right in to the MovingIN dashboard.
          </p>
          <p>
            Everything they need to get ready for moving in day, all in one
            easy-to-use dashboard.
          </p>
        </div>
      </div>
    </>
  );
}

export default RequiredTasks;
